import React from "react";

import Layout from "../Components/Layout";
import AboutUs from "../Components/AboutUs";

const AboutUsPage = () => {
  return (
    <Layout showHeader>
      <AboutUs />
    </Layout>
  );
};

export default AboutUsPage;
